import React, { useState } from 'react';
import { Container, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import Swal from 'sweetalert2';
import InfoIcon from '@material-ui/icons/Info';
import withReactContent from 'sweetalert2-react-content';
import { useTranslation } from 'react-i18next';

type ClassNames = 'supportContainer' | 'searchContainer' | 'input' | 'iconButton' | 'title' | 'newTicketLine';

const useStyles = makeStyles<Theme, Record<string, never>, ClassNames>((theme: Theme) =>
  createStyles({
    supportContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      // backgroundColor: '#f4f4f4',
    },
    searchContainer: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 600,
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    title: {
      fontSize: theme.typography.pxToRem(28),
      padding: '30px 0px',
      color: theme.palette.secondary.dark,
      opacity: 0.7,
    },
    newTicketLine: {
      color: theme.palette.secondary.dark,
      padding: '50px 0',
      fontSize: theme.typography.pxToRem(18),

      '& a': {
        fontWeight: 600,
        outline: 'none',
        color: '#6777ec',

        '&:visited': {
          color: '#6777ec',
        },
      },
    },
  })
);

const MySwal = withReactContent(Swal);

export default function SupportPageUser() {
  const [searchValue, setSearchValue] = useState('');
  const classes = useStyles({});
  const { t } = useTranslation();

  const search = async () => {
    await MySwal.fire({
      text: t('Knowledge base is still under construction, stay tune..'),
      title: <InfoIcon fontSize="large" />,
    });
  };

  const alertWIP = async () => {
    await MySwal.fire({
      text: t('Knowledge base is still under construction, stay tune..'),
      title: <InfoIcon fontSize="large" />,
    });
  };

  return (
    <Container className={classes.supportContainer}>
      <Typography className={classes.title}>for technical support issues email support@fairfare.co</Typography>
      {/* <Paper component="form" className={classes.searchContainer}>
        <InputBase
          className={classes.input}
          placeholder={t('Search')}
          inputProps={{ 'aria-label': 'search google maps' }}
          disabled
          onClick={alertWIP}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <IconButton className={classes.iconButton} aria-label="search" onClick={search}>
          <SearchIcon />
        </IconButton>
      </Paper>
      <Typography className={classes.newTicketLine}>
        {t('You can open a new ticket')}
        <Link to="/support/new">{t('here')}</Link>
        {t('or see your support ticket history')} <Link to="/support/history">{t('here')}</Link>
      </Typography> */}
    </Container>
  );
}
