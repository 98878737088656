import React, { useContext } from 'react';
import { Divider, makeStyles, Theme, Typography } from '@material-ui/core';
// import useSnackbar from '../../../hooks/useSnackbar';
import { Context as AuthContext } from '../../../contexts/authContext';
import { ChangePasswordForm } from '../../AccountPanel/ChangePasswordForm/ChangePasswordForm';
import { CardSettings } from './CardSettings';
import { APIKeysTable } from '../../AccountPanel/APIKeysTable/APIKeysTable';
import { TwoFactorAuth } from '../../AccountPanel/2FA/TwoFactorAuth';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    minWidth: 300,
  },
  divider: {
    width: '100%',
    marginTop: theme.spacing(10),
  },
  title: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    textDecoration: 'underline',
    fontSize: theme.typography.fontSize.toFixed(24),
    fontWeight: 'bold',
  },
  userPoolIndication: {
    marginBottom: 40,
    marginTop: 10,
    fontSize: 14,
    paddingLeft: 5,
    fontWeight: 60,
    color: '#7f8087a1',
  },
  card: {
    marginTop: 20,
    marginBottom: 20,
    padding: 20,
    border: '1px solid #e0e0e0',
    borderRadius: 5,
  },
  textFieldColor: {
    color: theme.palette.secondary.dark,
    fontWeight: 600,
  },
}));

export default function ProfileBusiness() {
  const { user } = useContext(AuthContext);
  const classes = useStyles();

  return (
    <div style={{ margin: '0 auto' }}>
      <Typography variant="h4" style={{ marginBottom: 6 }}>
        {`${user!.carrier?.displayName} Profile`}
      </Typography>
      <Typography variant="body1" component="span">
        <span style={{ fontWeight: 600 }}>User: </span>
        <span className={classes.textFieldColor}>{user!.fullName}</span> |
      </Typography>
      <Typography variant="body1" component="span">
        <span style={{ fontWeight: 600 }}> User Role: </span>
        <span className={classes.textFieldColor}>{user!.role} |</span>
      </Typography>
      <Typography variant="body1" component="span">
        <span style={{ fontWeight: 600 }}> Active since: </span>
        <span className={classes.textFieldColor}>{new Date(user!.createdAt!).toLocaleDateString()} | </span>
      </Typography>
      <CardSettings />
      <div style={{ width: 'fit-content' }}>
        <Divider style={{ marginTop: 30, marginBottom: 40 }} />
        <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
          API keys:
        </Typography>
        <div style={{ paddingBottom: 10 }}>
          <Typography variant="caption">The FairFare team will guide you on the where, and how, you should install this API key.</Typography>
        </div>
        <APIKeysTable />
        <Divider style={{ marginTop: 30, marginBottom: 40 }} />
        <TwoFactorAuth />
        <Typography className={classes.title}>Account Settings</Typography>
        <div style={{ marginTop: 20 }}>
          <ChangePasswordForm />
        </div>
        {/* <FormControl variant="outlined" style={{ marginTop: 16, marginBottom: 50, maxWidth: 350, display: 'flex' }} required fullWidth>
            <InputLabel id="lang-label">{t('Language')}</InputLabel>
            <Select labelId="lang-label" id="language" value={lang} onChange={(e: any) => changeLang(e.target.value)} label={t('Language')}>
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="he">עברית</MenuItem>
            </Select>
          </FormControl> */}
        <Divider />
      </div>
      {/* <Grid item xs={12}></Grid>
      <Grid item xs={12}></Grid> */}
    </div>
  );
}
