import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Badge,
} from "@material-ui/core";
import classnames from "classnames";
import NotificationsIcon from "@material-ui/icons/Notifications";

import useStyles from "../../../pages/HomePage.style";
import { DirectionOptions, useLanguageContext } from "../../../contexts/langContext";

type AppHeaderProps = {
  onDrawerOpen?: () => void;
  isOpen: boolean;
};

const getMargin = (direction: DirectionOptions, isOpen: boolean) => {
  if (direction === 'ltr') {
    return isOpen ? '240px' : '71px';
  }

  return isOpen ? '240px' : '71px';
}

export default function AppHeader({ isOpen }: AppHeaderProps) {
  const classes = useStyles();
  const { dir } = useLanguageContext();

  // marginLeft: drawerWidth,
  // width: `calc(100% - ${drawerWidth}px)`,
  return (
    <AppBar
      position="absolute"
      className={classnames(classes.appBar, isOpen && classes.appBarShift)}
      style={{
        width: `calc(100% - ${!isOpen ? "71px" : "240px"})`,
        marginLeft: dir === 'rtl' ? 0 : isOpen ? '240px' : '71px',
        marginRight: dir === 'ltr' ? 0 : isOpen ? '240px' : '71px'}}
    >
      <Toolbar className={classes.toolbar} variant="dense">
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
        >
          FairFare Dashboard
        </Typography>
        <IconButton color="inherit">
          <Badge badgeContent={0} color="secondary" overlap="rectangular">
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}
