import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, IconButton, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { CreateKeyModal } from './CreateKeyModal';
import { getBaseURL, getClient } from '../../../BLL/clients/HttpClient';
import { ApiEndPoint } from '../../../BLL/Enums/ApiEndPoint';
import { deletePAT } from '../../../services/authService';
import useSnackbar from '../../../hooks/useSnackbar';
import { ApiKeyInfo, ApiKeyInfoServerResponse, Severity } from '../../../types';
import { AxiosError } from 'axios';
import { logger } from '../../../services/logger';

const useStyles = makeStyles({
  table: {
    minWidth: 750,
  },
  active: {},
  new: {},
  revoked: {
    position: 'absolute',
    content: 'Revoked',
    display: 'block',
    zIndex: 1000,
    width: 50,
    height: 20,
    color: 'red',
    right: 22,
    top: 16,
    fontSize: 14,
    fontWeight: 'bold',
  },
});

export const APIKeysTable = () => {
  const classes = useStyles();
  const [keys, setKeys] = useState<ApiKeyInfo[]>([]);
  const [currentSelectedKey, setCurrentSelectedKey] = useState<ApiKeyInfo>();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isNewCustomer, setIsNewCustomer] = useState(false);
  const toast = useSnackbar();

  useEffect(() => {
    const run = async () => {
      try {
        const baseURL = getBaseURL(ApiEndPoint.Auth);
        const client = getClient({ baseURL });
        const res = await client.get<{ tokens: ApiKeyInfoServerResponse[] }>('v1/pat');
        setKeys(res.data.tokens.map((t) => ({ ...t, createdBy: t.createdBy.fullName })));
      } catch (error) {
        const e = error as AxiosError;
        if (e.response?.status === 401) {
          setIsNewCustomer(true);
        }
        logger.error('Failed to fetch PAT', { error: e.message });
      }
    };
    run();
  }, []);

  const handleOpen = () => {
    setCurrentSelectedKey(undefined);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreateKey = (key: ApiKeyInfo) => {
    const existingKey = keys.find((k) => k.name === key.name);
    if (existingKey) {
      throw new Error('Key with the same name already exists');
    }
    setKeys((k) => [...k, key]);
    handleClose();
  };

  const deleteHandler = async (key: ApiKeyInfo) => {
    setIsLoading(true);
    try {
      await deletePAT(key.name);
      setKeys((s) => [...s.filter((x) => x.name !== key.name)]);
      toast('revoke token success', Severity.success);
    } catch (error) {
      toast('Failed to revoke token', Severity.error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isNewCustomer) {
    return (
      <>
        <Typography variant="caption" component="p">Your account is not been activated, this can only be done by FairFare team</Typography>
        <Typography variant="caption" component="p">Please contact FairFare to activate your account to create API keys</Typography>
      </>
    );
  }

  return (
    <div>
      <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleOpen}>
        Create Key
      </Button>
      {keys.length > 0 && (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Secret Key</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Last Used</TableCell>
                <TableCell>Created By</TableCell>
                <TableCell>Permissions</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {keys.map((key) => (
                <TableRow key={key.name}>
                  <TableCell style={{ textDecoration: key.status === 'revoked' ? 'line-through' : 'none' }}>{key.name}</TableCell>
                  <TableCell>********</TableCell>
                  <TableCell>{key.createdAt}</TableCell>
                  <TableCell>{key.updatedAt}</TableCell>
                  <TableCell>{key.createdBy}</TableCell>
                  <TableCell>{key.permissions}</TableCell>
                  <TableCell style={{ position: 'relative' }}>
                    {key.status === 'revoked' ? <p className={classes.revoked}>Revoked</p> : null}
                    <IconButton onClick={() => deleteHandler(key)} disabled={isLoading || key.status !== 'active'}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <CreateKeyModal open={open} onClose={handleClose} onCreate={handleCreateKey} currentKey={currentSelectedKey} />
    </div>
  );
};
