import React, { useState } from 'react';
import clsx from 'clsx';
import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Box, Button } from '@material-ui/core';
import { useLanguageContext } from '../../contexts/langContext';
import { Claim } from '../../types';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  columns: DasDataSetCol[];
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {props.columns.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            sortDirection={orderBy === headCell.id ? order : false}
            // padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(String(headCell.id))}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
    },
  })
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  })
);

type DasDataSetProps = {
  onActionClick?: (id: string | number, columnId: string) => void;
  columns: DasDataSetCol[];
  rows: Claim[];
};

export interface DasDataSetCol {
  id: string | number;
  label: string;
  minWidth?: number;
  width?: number;
  align?: 'right' | 'left';
  type: 'text' | 'action' | 'id' | 'date';
  dateFormat?: (date: Date) => string;
  shouldDisableCta?: (row: Claim) => boolean;
}

export default function EnhancedTable({ onActionClick, columns, rows }: DasDataSetProps) {
  const classes = useStyles();
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { dir } = useLanguageContext();
  const [currentClaimId, setCurrentClaimId] = useState<string | number>('');

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getText = (value: any) => {
    if (value instanceof Date) {
      return value.toLocaleDateString();
    }
    if (value instanceof Number) {
      return value.toFixed(2);
    }
    return value;
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const handleShowDetails = (id: string | number, columnId: string) => {
    if (onActionClick) {
      onActionClick(id,columnId);
    }
    if (currentClaimId === id) {
      setCurrentClaimId('');
      return;
    }
    setCurrentClaimId(id);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <TableContainer>
          <Table className={classes.table} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'} aria-label="enhanced table">
            <EnhancedTableHead classes={classes} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} columns={columns} />
            <TableBody>
              {stableSort<any>(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: Claim) => {
                  return (
                    <>
                      <TableRow
                        tabIndex={-1}
                        key={`claim_${row.id}`}
                      >
                        {columns.map((col) => (
                          <TableCell dir={dir} style={{ width: col.width || 160 }} align={col.align} key={`row:${row.id}_col:${col.id}`}>
                            {col.type !== 'action' ? (
                              getText(row[col.id as keyof Claim])
                            ) : (
                              <Button
                                variant="outlined"
                                color="primary"
                                disabled={col.shouldDisableCta ? col.shouldDisableCta(row) : false}
                                onClick={() => handleShowDetails(row.id, col.id.toString())}
                              >
                                {col.label}
                              </Button>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                      {currentClaimId === row.id && (
                        <>
                          <TableRow style={{ height: 20 }}></TableRow>
                          <TableRow>
                            <TableCell style={{ borderBottom: 'none' }}></TableCell>
                            <TableCell style={{ borderBottom: 'none' }}></TableCell>
                            <TableCell style={{ backgroundColor: '#e3e3e3' }}>ICD</TableCell>
                            <TableCell style={{ backgroundColor: '#e3e3e3' }}>ICD Description</TableCell>
                            <TableCell style={{ backgroundColor: '#e3e3e3' }}>CPT</TableCell>
                            <TableCell style={{ backgroundColor: '#e3e3e3' }}>CPT Description</TableCell>
                            <TableCell style={{ backgroundColor: '#e3e3e3' }}>Billed Amount</TableCell>
                            <TableCell style={{ backgroundColor: '#e3e3e3' }}>Allowed Amount</TableCell>
                            <TableCell style={{ borderBottom: 'none' }}></TableCell>
                            <TableCell style={{ borderBottom: 'none' }}></TableCell>
                          </TableRow>
                          {(row.cpts || []).map((charge) => (
                            <TableRow key={`${row.id}_${charge.cpt}`}>
                              <TableCell style={{ borderBottom: 'none' }}></TableCell>
                              <TableCell style={{ borderBottom: 'none' }}></TableCell>
                              <TableCell style={{ borderLeft: '1px solid rgba(224, 224, 224, 1)' }}>{charge.icd}</TableCell>
                              <TableCell>{charge.icdDescription}</TableCell>
                              <TableCell>{charge.cpt}</TableCell>
                              <TableCell>{charge.cptDescription}</TableCell>
                              <TableCell>{Number(charge.billedAmount).toFixed(2)}</TableCell>
                              <TableCell style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                {Number(charge.allowedAmount).toFixed(2)}
                              </TableCell>
                              <TableCell style={{ borderBottom: 'none' }}></TableCell>
                              <TableCell style={{ borderBottom: 'none' }}></TableCell>
                            </TableRow>
                          ))}
                          <TableRow style={{ height: 20 }}></TableRow>
                        </>
                      )}
                    </>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Dense padding" />
    </div>
  );
}
