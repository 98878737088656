import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import { Typography, CircularProgress, makeStyles } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Context as AuthContext } from '../../../contexts/authContext';

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(4),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(2, 0),
  },
  codeInput: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    alignContent: 'center',
    justifyContent: 'center',
  },
  codeBox: {
    width: '40px',
    height: '40px',
    fontSize: '24px',
    textAlign: 'center',
    marginRight: theme.spacing(1),
  },
}));

export const Login2FA = () => {
  const [code, setCode] = useState(new Array(6).fill(''));
  const inputs = useRef<any>([]);
  const location = useLocation<{ email: string; password: string }>();
  const { email, password } = location.state || { email: '', password: '' };
  const { signIn, errors, isLoading, clearState } = useContext(AuthContext);
  const classes = useStyles();

  useEffect(() => {
    if (clearState) {
      clearState();
    }
  }, []);

  const onSignIn = async () => {
    if (signIn) {
      await signIn(email, password, code.join(''));
    }
  };

  const handleCodeChange = (index: number, e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value)) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      if (index < 5) {
        inputs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Backspace') {
      const newCode = [...code];
      newCode[index] = '';
      setCode(newCode);

      if (index > 0) {
        inputs.current[index - 1].focus();
      }
    }
  };

  return (
    <div className={classes.paper}>
      {errors && errors.serverError && <Typography color="error">Failed, Please check user name and password and try again</Typography>}
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Please Enter 2FA Code
      </Typography>
      <form className={classes.form} noValidate>
        <Box className={classes.codeInput}>
          {[0, 1, 2, 3, 4, 5].map((_, index) => (
            <TextField
              key={index}
              className={classes.codeBox}
              variant="outlined"
              inputProps={{
                maxLength: 1,
                style: { textAlign: 'center' },
                'aria-label': `Digit ${index + 1}`,
              }}
              value={code[index]}
              onChange={(e) => handleCodeChange(index, e)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              inputRef={(el) => (inputs.current[index] = el)}
            />
          ))}
        </Box>
        <Button fullWidth variant="contained" color="primary" className={classes.submit} onClick={onSignIn} disabled={isLoading}>
          {!isLoading ? 'Log in' : <CircularProgress color="inherit" />}
        </Button>
        <Box mt={5}>
          <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.fairfare.co/">
              FairFare
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        </Box>
      </form>
    </div>
  );
};
