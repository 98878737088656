import React, { useCallback, useEffect, useState } from 'react';
import DasDataSet, {
  DasDataSetCol,
} from '../components/DasDataSet/EnhancedTable';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../contexts/authContext';
import { Box, Container, Typography } from '@material-ui/core';
import { approveClaim, getAllClaims } from '../services/claimsService';
import { Claim } from '../types';

type ClaimsPageData = {
  entities: Claim[];
  status: 'loading' | 'done' | 'error';
  error: null | Error;
};

export default function ClaimsPage() {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const [pageData, setPageData] = useState<ClaimsPageData>({
    entities: [],
    status: 'loading',
    error: null,
  });

  const col: DasDataSetCol[] = [
    {
      id: 'id',
      label: t('Claim Id'),
      width: 180,
      type: 'id',
    },
    {
      id: 'policyNumber',
      label: t('Policy Number'),
      type: 'text',
    },
    {
      id: 'status',
      label: t('Status'),
      width: 100,
      type: 'text',
    },
    {
      id: 'dateOfService',
      label: t('Date of Service'),
      type: 'text',
    },
    {
      id: 'providerName',
      label: t('Provider Name'),
      width: 250,
      type: 'date',
    },
    {
      id: 'npi',
      label: t('NPI'),
      type: 'text',
    },
    {
      id: 'totalBilledAmount',
      label: 'Billed Amount',
      type: 'text',
    },
    {
      id: 'totalAllowedAmount',
      label: 'Allowed Amount',
      type: 'text',
    },
    {
      id: 'collectionDate',
      label: 'Collection Date',
      type: 'text',
    },
    {
      type: 'action',
      label: 'Details',
      id: 'action',
      shouldDisableCta: (row) =>
        row.status !== 'close' ||
        (row.status === 'close' && row.totalBilledAmount === 0),
    },
    {
      type: 'action',
      label: 'Approve',
      id: 'approveAction',
      shouldDisableCta: (row) =>
        (row.status !== 'close' && row.status !== 'approved') ||
        (row.totalAllowedAmount > 2000),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const claims = await getAllClaims();
        setPageData({ entities: claims, status: 'done', error: null });
      } catch (error) {
        const e = error as Error;
        setPageData({ entities: [], status: 'error', error: e });
      }
    };
    fetchData();
  }, []);

  const onClickHandler = useCallback(async (claimId: string | number, action: string) => {
    const row = pageData.entities.find((r) => r.id === claimId);
    if (action === 'action') {
      console.log('details', row);
    } else if (action === 'approveAction') {
      await approveClaim(claimId);
    }
  }, [pageData]);

  if (!user) {
    return <div>{t('Loading')}..</div>;
  }

  if (user.status === 'new') {
    return (
      <Container>
        <Typography variant="h4" component="p">
          Welcome to FairFare Dashboard!
        </Typography>
        <Typography component="p">
          To activate your account and access all FairFare features please
          contact us
        </Typography>
      </Container>
    );
  }
  if (pageData.status === 'loading') {
    return <div>{t('Loading')}..</div>;
  }

  if (pageData.error) {
    return <div>{t('Error')}..</div>;
  }

  if (pageData.entities.length === 0) {
    return (
      <Box
        display="flex"
        alignContent="center"
        flexDirection="column"
        justifySelf="center"
        margin="0 auto"
      >
        <img
          src="/no-res.png"
          alt="No Claims"
          style={{ width: '100px', height: 'auto', margin: '0 auto' }}
        />
        <Typography style={{ marginTop: 40 }} variant="h4">
          There are no claims in the system currently
        </Typography>
      </Box>
    );
  }

  return (
    <div style={{ maxWidth: '80vw', margin: '0 auto' }}>
      <DasDataSet columns={col} rows={pageData.entities} onActionClick={onClickHandler} />
    </div>
  );
}
